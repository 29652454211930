import React from "react";
import { connect } from "react-redux";
import { updateBannerInstructionsAction } from "../../store/reducers/layout/actions";
import { getBannerInstructions } from "../../store/reducers/layout/selector";
const BannerInstructions = ({
  updateBannerInstructions,
  bannerInstructions,
}) => {
  const closeComponent = () => {
    updateBannerInstructions({
      state: false,
      title: bannerInstructions.title,
      description: bannerInstructions.description,
    });
  };
  const { state, title, description } = bannerInstructions;
  return (
    <div
      className={`messages-component-instructions-black-wall ${
        state ? "" : "close"
      }`}
    >
      <div
        className={`messages-component-instructions ${state ? "" : "close"}`}
      >
        <div className="messages-component-instructions-title">
          {title}
          <button
            onClick={closeComponent}
            className={`messages-component-instructions-button ${
              state ? "" : "close"
            }`}
          >
            <svg
              className="messages-component-instructions-button-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352 512"
            >
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
            </svg>
          </button>
        </div>
        <div className="messages-component-instructions-description">
          {description}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    bannerInstructions: getBannerInstructions(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBannerInstructions: (data) => {
      dispatch(updateBannerInstructionsAction(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BannerInstructions);
