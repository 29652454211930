const dogBreeds = [
  "Affenpinscher",
  "Airedale terrier",
  "Aïdi",
  "Akita Inu",
  "Akita Americano",
  "Alano español",
  "Alaskan malamute",
  "Alaskan Klee Kai",
  "American Hairless terrier",
  "American Staffordshire Terrier",
  "Antiguo Perro Pastor Inglés",
  "Appenzeller",
  "Australian Cattle Dog",
  "Australian terrier",
  "Australian Silky Terrier",
  "Azawakh",
  "Bardino",
  "Bandog",
  "Basenji",
  "Basset azul de Gascuña",
  "Basset hound",
  "Beagle",
  "Beauceron",
  "Bedlington terrier",
  "Bergamasco",
  "Bichon frisé",
  "Bichón maltés",
  "Bichón habanero",
  "Bobtail",
  "Bloodhound",
  "Border collie",
  "Borzoi",
  "Boston terrier",
  "Bóxer",
  "Boyero de Berna",
  "Boyero de Flandes",
  "Braco alemán de pelo corto",
  "Braco alemán de pelo duro",
  "Braco de Auvernia",
  "Braco francés",
  "Braco húngaro",
  "Braco italiano",
  "Braco tirolés",
  "Braco de Saint Germain",
  "Braco de Weimar",
  "Bull Terrier",
  "Bulldog americano",
  "Bulldog francés",
  "Bulldog inglés",
  "Bullmastiff",
  "Buhund noruego",
  "Calupoh",
  "Can de palleiro",
  "Caniche",
  "Cão da Serra da Estrela",
  "Cão da Serra de Aires",
  "Cão de Agua Português",
  "Cão de Castro Laboreiro",
  "Cão de Fila de São Miguel",
  "Cavalier King Charles Spaniel",
  "Cazador de alces noruego",
  "Chesapeake Bay Retriever",
  "Chihuahueño",
  "Crestado Chino",
  "Cimarrón Uruguayo",
  "Cirneco del Etna",
  "Chow chow",
  "Clumber spaniel",
  "Cobrador de pelo liso",
  "Cocker spaniel americano",
  "Cocker spaniel inglés",
  "Collie de pelo corto",
  "Collie de pelo largo",
  "Bearded collie",
  "Corgi galés de Cardigan",
  "Cruzado",
  "Dachshund",
  "Dálmata",
  "Dandie Dinmont Terrier",
  "Deerhound",
  "Dobermann",
  "Dogo alemán",
  "Dogo argentino",
  "Dogo de burdeos",
  "Dogo del Tíbet",
  "Dogo guatemalteco",
  "English springer spaniel",
  "Entlebucher",
  "Épagneul bretón",
  "Épagneul français",
  "Epagneul papillón",
  "Eurasier",
  "Fila Brasileiro",
  "Flat-Coated Retriever",
  "Fox Terrier",
  "Foxhound americano",
  "Galgo español",
  "Galgo húngaro",
  "Galgo inglés",
  "Galgo italiano",
  "Golden retriever",
  "Glen of Imaal Terrier",
  "Gran danés",
  "Gegar colombiano",
  "Greyhound",
  "Grifón belga",
  "Hovawart",
  "Husky siberiano",
  "Jack Russell Terrier",
  "Keeshond",
  "Kerry blue terrier",
  "Komondor",
  "Kuvasz",
  "Labrador",
  "Lakeland Terrier",
  "Laekenois",
  "Landseer",
  "Lebrel afgano",
  "Lebrel polaco",
  "Leonberger",
  "Lobero irlandés",
  "Lundehund",
  "Perro lobo de Saarloos",
  "Lhasa apso",
  "Löwchen",
  "Maltés",
  "Malinois",
  "Manchester terrier",
  "Mastín afgano",
  "Mastín del Pirineo",
  "Mastín español",
  "Mastín inglés",
  "Mastín italiano",
  "Mastín napolitano",
  "Mastín tibetano",
  "Mucuchies",
  "Mudi",
  "Münsterländer grande",
  "Münsterländer pequeño",
  "Nova Scotia Duck Tolling Retriever",
  "Ovejero magallánico",
  "Otro",
  "Pastor alemán",
  "Pastor belga",
  "Pastor blanco suizo",
  "Pastor catalán",
  "Pastor croata",
  "Pastor garafiano",
  "Pastor holandés",
  "Pastor peruano Chiribaya",
  "Pastor de Brie",
  "Pastor de los Pirineos",
  "Pastor leonés",
  "Pastor mallorquín",
  "Pastor maremmano-abrucés",
  "Pastor de Valée",
  "Pastor vasco",
  "Pekinés",
  "Pembroke Welsh Corgi",
  "Pequeño Lebrel Italiano",
  "Perdiguero francés",
  "Perdiguero portugués",
  "Perro cimarrón uruguayo",
  "Perro de agua americano",
  "Perro de agua español",
  "Perro de agua irlandés",
  "Perro de agua portugués",
  "Perro de Groenlandia",
  "Perro de osos de Carelia",
  "Perro dogo mallorquín",
  "Perro esquimal canadiense",
  "Perro de Montaña de los Pirineos",
  "Perro fino colombiano",
  "Perro pastor de las islas Shetland",
  "Perro peruano sin pelo",
  "Phalène",
  "Pinscher alemán",
  "Pinscher miniatura",
  "Pitbull",
  "Podenco canario",
  "Podenco ibicenco",
  "Podenco portugués",
  "Pointer",
  "Pomerania",
  "Presa canario",
  "Pudelpointer",
  "Pug",
  "Puli",
  "Pumi",
  "Rafeiro do Alentejo",
  "Ratonero bodeguero andaluz",
  "Ratonero mallorquín",
  "Ratonero valenciano",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Saluki",
  "Samoyedo",
  "San Bernardo",
  "Schapendoes",
  "Schnauzer estándar",
  "Schnauzer gigante",
  "Schnauzer miniatura",
  "Staffordshire Bull Terrier",
  "Sabueso bosnio",
  "Schipperke",
  "Sealyham terrier",
  "Setter inglés",
  "Setter irlandés",
  "Shar Pei",
  "Shiba Inu",
  "Shih Tzu",
  "Shikoku Inu",
  "Siberian husky",
  "Skye terrier",
  "Spaniel japonés",
  "Spaniel tibetano",
  "Spitz enano",
  "Spitz grande",
  "Spitz mediano",
  "Spitz japonés",
  "Sussex spaniel",
  "Teckel",
  "Terranova",
  "Terrier alemán",
  "Terrier brasileño",
  "Terrier checo",
  "Terrier chileno",
  "Terrier de Norfolk",
  "Terrier de Norwich",
  "Terrier escocés",
  "Terrier galés",
  "Terrier irlandés",
  "Terrier ruso negro",
  "Terrier tibetano",
  "Toy spaniel inglés",
  "Tervueren",
  "Raza Undefinida",
  "Vallhund sueco",
  "Volpino italiano",
  "Weimaraner",
  "West Highland White Terrier",
  "Whippet",
  "Wolfsspitz",
  "Xoloitzcuintle",
  "Yorkshire terrier",
]

export default dogBreeds;