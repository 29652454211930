import React from "react";
import InputMap from "./InputMap";

const RightColumnMapPage = () => {
  return (
    <>
      <InputMap>
      </InputMap>
    </>
  )
}

export default RightColumnMapPage;